$primary-color: #64ffda;

@import 'animate.css';
@import '~loaders.css/src/animations/cube-transition.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

// input,
// textarea {
//   font-family: 'Helvetica Neue';
// }


@import '~bootstrap/scss/bootstrap';