.footer {
  text-align: center;

  .footer-icons {
      display: flex;
      justify-content:flex-start;
      list-style-type: none;
      padding: 0;

      li {
          display: inline-block;
          margin: 0 15px;

          a {
              color: #fff;
              font-size: 24px;
              transition: color 0.3s;

              &:hover {
                  color: #64ffda;
              }
          }
      }
  }
}