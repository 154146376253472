@media (max-width: 767px){
  .about-page {
    display: none;
    h1 {
      font-size: 24px;
      font-family: 'Coolvetica';
      color: #64ffda;
      font-weight: 400;
      position: relative;
      margin-bottom: 20px;
      margin-top: 8px;
    }

    a {
      font-family: sans-serif; 
      font-weight: 600; 
      color: #fff;
      opacity: 0.9;
      text-decoration: none; 
      transition: color 0.3s;
    }

    p {
      font-size: 16px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      opacity: 0.7;
      max-width: fit-content;
      
    }
  }
}

@media (min-width: 768px){
  .about-page {
    margin-top:5vh;
    h1 {
      font-size: 3rem;
      font-family: 'Coolvetica';
      color: #64ffda;
      font-weight: 400;
      position: relative;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    a {
      font-family: sans-serif; 
      font-weight: 600; 
      color: white;
      opacity: 1;
      text-decoration: none; 
      transition: color 0.3s; 
    }

    a:hover {
      color: #64ffda;
    }
  
    p {
      font-size: 1rem;
      line-height:1.6;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      opacity: 0.8;
    }
  }
}

