// .card-spotlight-effect {
//   position: relative;
//   max-width: 24rem;
//   overflow: hidden;
//   border-radius: 0.875rem;
//   border: 1px solid #374151;
//   background-image: linear-gradient(to right, #0f172a, #06171b);
//   padding: 4rem 2rem;
//   box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
// }

.spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

