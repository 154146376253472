html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1b0234;
  display: block;
}
