@media (max-width: 767px) {
  .home-page {
    .nav {
      display: none;
    }

    h1 {
      color: white;
      font-size: 32px;
      margin: 10px 10px 10px 0;
      font-family: 'Coolvetica';
      font-weight: 400;
    }
  
    h2 {
      color:#8d8d8d;
      margin-top:8px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 16px;
      font-family:sans-serif;
      letter-spacing: 3px;
    }
  
    .flat-button {
      color:#64ffda;
      background: transparent;
      font-size: 13px;
      font-weight:400;
      letter-spacing: 4px;
      font-family:sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #64ffda;
      margin: 0;
      white-space: nowrap;
  
    }

    .footer {
      width: 100%;
      display: block;
      margin-top: 16px;
    }
  }
}

@media(min-width: 768px){
  .home-page {
    position: sticky;
    top: 5vh;
    height: 100vh;
    overflow-y: auto;
    padding-left: 48px;

    .nav {
      display: flex;
      flex-direction: column;

      .nav-item {
        padding: 0;

        .nav-link {
          display: flex; 
          font-size: 18px;
          align-items: center; 
          color: slategray; 
          padding: 10px 0; 
          text-decoration: none; 
          transition: transform 0.3s ease; 
          position: relative;

          &::before { 
            content: '';
            position: absolute;
            left: -35px; 
            top: 50%; 
            transform: translateY(-50%); 
            height: 2px; 
            width: 30px; 
            background-color: white;
            transform: scaleX(0); 
            transform-origin: left;
            transition: transform 0.3s ease;
          }

          &.active, &:hover { 
            color: white; 
            transform: translateX(35px); 

            &::before { // Show the horizontal bar on active link or on hover
              transform: scaleX(1);
            }
          }
        }
      }
    }

    .footer {
      position: absolute; 
      bottom: 5vh;          // Stick it to the bottom.
      width: 100%;        // Ensure it takes up the full width of the container.
    }
  

    
  h1 {
    color: white;
    font-size: 4rem;
    margin:0;
    font-family: 'Coolvetica';
    font-weight: 400;
    position: relative;

    .text-animate-hover {
      margin: 1px;
      display: inline-block;
      animation-fill-mode: both;
  
      &:hover {
        animation: rubberBand 1s;
        color: #64ffda;
      }
    }

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #64ffda;
      font-size: 22px;
      position: absolute;
      top:0;
      left:-40px;
      opacity: 0.8;
    }

    &::after {
      content: '<h1 />';
      font-family: 'La Belle Aurore';
      color: #64ffda;
      font-size: 22px;
      position: absolute;
      bottom:-15px;
      opacity:0.8;
    }
  }

  h2 {
    color:#8d8d8d;
    margin-top:2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 2rem;
    font-family:sans-serif;
    letter-spacing: 3px;
  }

  .flat-button {
    color:#64ffda;
    background:transparent;
    font-size: 13px;
    font-weight:400;
    letter-spacing: 4px;
    font-family:sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #64ffda;
    margin-top: 25px;
    float: left;
    // animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #64ffda;
      color: #333;
    }
  }
}
}
