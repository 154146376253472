@media(max-width: 767px){
  .work-container{
    padding: 12px 0;
  }
  .custom-stack {
    flex-direction: column;
  }
  .custom-pill {
    font-size: 12px;     
  }
  .section-heading {
    font-size: 24px;
    font-family: 'Coolvetica';
    color: #64ffda;
    font-weight: 400;
    position: relative;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .work-experience {
    padding: 20px 0; 
    border-radius: 8px;
    transition: background-color 0.3s, opacity 0.3s;
    opacity: 1;
  
    h1{
      font-size: 20px;
      font-family: 'Coolvetica';
      color: #fff;
      font-weight: 400;
    }
    h2{
      font-size: 16px;
      font-family: 'Helvetica Neue';
      font-weight:300;
      color:#fff;
      opacity: 0.8;
     
    }
  
    p{
      font-size:14px;
      color:#fff;
      font-family: sans-serif;
      font-weight:300;
      opacity: 0.8;
      margin-bottom: 0;
    }
  }
}

@media(min-width: 768px){
  .work-container{
    padding: 12px 0;
  }
  .custom-pill {
    font-size: 14px;     
  }
  .custom-stack {
    flex-direction:row;
  }
  .section-heading {
    font-size: 3rem;
    font-family: 'Coolvetica';
    color: #64ffda;
    font-weight: 400;
    position: relative;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .work-experience {
  padding: 20px 0; 
  border-radius: 8px;
  transition: background-color 0.3s, opacity 0.3s;
  opacity: 1;
  

  .date{
    font-size: 1rem;
    font-family: 'Helvetica Neue';
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 0;
  }

  .enddate{
    margin-top: 0;
  }

  h1{
    font-size: 2rem;
    font-family: 'Coolvetica';
    color: #fff;
    font-weight: 400;
  }
  h2{
    font-size: 1.5rem;
    font-family: 'Helvetica Neue';
    font-weight:300;
    color:#fff;
    opacity: 0.8;
  }

  p{
    font-size:1rem;
    color:#fff;
    font-family: sans-serif;
    font-weight:300;
    opacity:0.5;
    line-height:1.6;
  }
}

.work-experience:hover {
  background-color: rgba(10, 25, 47, 0.8);
}
}
